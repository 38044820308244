<template>
  <div class="pages">
    <div class="page">
      <div class="inside-nav">
        <el-tree
          :data="treeData"
          :props="defaultProps"
          :ight-current="true"
          accordion
          @node-click="handleNodeClick"
        >
        </el-tree>
      </div>

      <div class="task_box">
        <div class="out">
          <div class="btns" @click="exportlist">
            <div class="el-icon el-icon-upload2"></div>
            <div>导&nbsp;出</div>
          </div>
        </div>
        <!-- select 筛选 -->
        <div class="select_box"></div>
        <div class="oper_box">
          <div class="s_item s_item_s">
            <div class="s_lable">开始时间：</div>
            <el-date-picker
              v-model="from.start"
              type="date"
              placeholder="选择日期"
              @change="startChange(from.start)"
            >
            </el-date-picker>
          </div>
          <div class="s_item s_item_s">
            <div class="s_lable">结束时间：</div>
            <el-date-picker
              v-model="from.end"
              type="date"
              placeholder="选择日期"
              @change="endChange(from.end)"
            >
            </el-date-picker>
          </div>
          <div class="s_item s_item_s">
            <div class="s_lable s_lable_price">电费单价：</div>
            <el-input v-model="from.price" placeholder="请输入电费单价">
            </el-input>
          </div>
          <div class="btns" @click="submit">查询</div>
        </div>
        <div class="table">
          <table align="center" border="1">
            <tr>
              <td colspan="11">电费电量统计表</td>
            </tr>
            <tr>
              <td rowspan="2">设备名称</td>
              <td rowspan="2">总电量(Kw/h)</td>
              <td rowspan="2">总电费(元)</td>
              <td colspan="2">尖</td>
              <td colspan="2">峰</td>
              <td colspan="2">平</td>
              <td colspan="2">谷</td>
            </tr>
            <tr>
              <td>尖电量(Kw/h)</td>
              <td>尖电费(元)</td>
              <td>峰电量(Kw/h)</td>
              <td>峰电费(元)</td>
              <td>平电量(Kw/h)</td>
              <td>平电费(元)</td>
              <td>谷电量(Kw/h)</td>
              <td>谷电费(元)</td>
            </tr>
            <tbody v-for="item in tableList">
              <tr>
                <td rowspan="2">{{ item.name }}</td>
                <th rowspan="2">{{ item.totalElectricity }}</th>
                <th rowspan="2">{{ item.totalElectricCharge }}</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      time: "",
      selectValue: "",
      // 副 菜单
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      from: {
        organize_id: "",
        start: "",
        end: "",
        price: "",
      },
      tableList: [],
    };
  },
  mounted: function () {
    this.getzuzhiList();
  },
  methods: {
    // 导出
    exportlist() {
      console.log(this.from.price, "this.from.price");

      if (this.from.organize_id == "") {
        this.$message.error("请选择组织");
        return;
      }
      if (this.from.start == "") {
        this.$message.error("请选择开始");

        return;
      }
      if (this.from.end == "") {
        this.$message.error("请选择结束");

        return;
      }
      if (this.from.price == "") {
        this.$message.error("请选择电费单价");
        return;
      }
      // return
      window.location.href =
        "http://211.149.176.35:9001/api/device/export_electric_charge?organize_id=" +
        this.from.organize_id +
        "&start=" +
        this.from.start +
        "&end=" +
        this.from.end +
        "&price=" +
        this.from.price;
    },
    async submit() {
      const res = await this.$http.post(
        "device/electric_charge_report",
        this.from
      );
      if (res.code !== 1) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      this.tableList = res.data;
    },
    // 开始时间选择
    startChange(val) {
      const newDate =
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
      this.from.start = newDate;
    },
    // 结束时间选择
    endChange(val) {
      const newDate =
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
      this.from.end = newDate;
    },
    // 组织 获取
    async getzuzhiList() {
      const res = await this.$http.post("Organize");
      this.treeData = res.data;
    },
    handleNodeClick(data) {
      // 组织
      console.log(data);
      this.from.organize_id = data.id;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background: #68d8fe;
  color: #fff;
}
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

// 副菜单
.inside-nav {
  width: 200px;
  height: calc(100% - 40px);
  margin-top: 20px;
  box-sizing: border-box;
  border: #61cdf1 2px solid;
  background-color: #1b284d;
  overflow-y: auto;
}
/* 左侧二级菜单滚动条 设置滚动条 */
.inside-nav::-webkit-scrollbar {
  width: 4px;
}
.inside-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #68d8fe;
}
.inside-nav::-webkit-scrollbar-track {
  border-radius: 0;
  background: #1b284d;
}
/* 设置滚动条  end */

.el-tree {
  background-color: #1b284d;
  color: #ffffff;
}
::v-deep .el-tree-node__label {
  font-size: 14px;
}
::v-deep .el-tree-node__content {
  height: 30px;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #1b284d;
  color: #68d8fe;
}
::v-deep .el-tree-node__content:hover,
::v-deep .el-upload-list__item:hover {
  background-color: #1b284d;
  color: #68d8fe;
}
.out {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btns {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    margin-left: 25px;
    background-color: #68d8fe;
    border-radius: 5px;
    width: 70px;

    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    cursor: pointer;
    .el-icon {
      font-size: 20px;
      margin-right: 5px;
    }
  }
}
// 表格
.task_box {
  width: calc(100% - 220px);
  ::v-deep .el-input__inner {
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #e5e5e5 !important;
    border: none;
    background: rgba(0, 0, 0, 0) !important;
    border: 1px solid #61cdf1 !important;
  }

  // 顶部按钮
  .elmenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .el-menu.el-menu--horizontal {
      border: 1px solid #61cdf1 !important;
    }
    .el-menu.el-menu--horizontal {
      border: none !important;
    }
    .el-menu {
      background-color: rgba(0, 0, 0, 0) !important;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-menu--horizontal > .el-menu-item {
      color: #fff !important;
    }
    .el-menu-item.is-active {
      color: #68d8fe !important;
    }

    .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    .el-menu-item {
      border-left: 0px;
      margin-right: 20px;
    }
  }

  .oper_box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    margin-bottom: 20px;

    .btns {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #ffffff;
      margin-left: 25px;
      background-color: #68d8fe;
      border-radius: 5px;
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      cursor: pointer;
      .el-icon {
        font-size: 20px;
        margin-right: 5px;
      }
    }
    .s_item {
      display: flex;
      align-items: center;
      margin-left: 80px;
      .s_lable {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // width: 110px;
        font-size: 16px;
        color: #ffffff;
      }
      .s_lable_price {
        width: 110px;
      }
    }
  }

  // select 筛选
  .index_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-tag {
      margin-right: 10px;
    }
  }
  .select_box {
    display: flex;
    align-items: center;
    padding: 15px;
    .s_item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
      }
      .s_lable_price {
        width: 110px;
      }
    }
    .btns {
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #68d8fe;
      font-size: 16px;
      color: #ffffff;
      width: 80px;
      border-radius: 5px;
      cursor: pointer;
      margin-left: 20px;
    }
  }
}
.s_item_s {
  margin-right: 30px;
  margin-left: 0px !important;
}
.table {
  margin-top: 30px;
  height: 750px;
  overflow-y: auto;
  table,
  th,
  td {
    border: 1px solid #61cdf1;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #e5e5e5;
    border-collapse: collapse;
  }
  table {
    width: 100%;
    border: 2px solid #61cdf1;
  }
  th {
    height: 43px;
    width: 45px;
  }
  td {
    text-align: center;
    width: 45px;
    height: 43px;
  }
}
</style>
